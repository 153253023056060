<script setup>
import { Link } from '@inertiajs/vue3';
import { computed, useSlots } from 'vue';
import DefaultInner from './Partials/DefaultInner.vue';

const props = defineProps({
    icon: {
        type: String,
        required: false,
    },
    iconRight: {
        type: Boolean,
        required: false,
    },
    text: String,

    href: {
        type: String,
        required: true,
    },
    danger: {
        type: Boolean,
        required: false,
        default: false,
    },
    warning: {
        type: Boolean,
        required: false,
        default: false,
    },
    white: {
        type: Boolean,
        required: false,
        default: false,
    },
    pink: {
        type: Boolean,
        required: false,
        default: false,
    },
    blue: {
        type: Boolean,
        required: false,
        default: false,
    },
    pinkTransparent: {
        type: Boolean,
        required: false,
        default: false,
    },
    transparent: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    method: {
        type: String,
        required: false,
        default: 'get',
    },
    data: {
        type: Object,
        required: false,
    },
    asA: Boolean,
    disabled: Boolean,
});

const as = computed(() => {
    return props.method == 'get' && !props.disabled ? 'a' : 'button';
});

const isMailOrTel = computed(() => {
    return props.href.includes('mailto:') || props.href.includes('tel:');
});

const component = computed(() => {
    return isMailOrTel.value || props.asA ? 'a' : Link;
});

const slots = useSlots();
</script>

<template>
    <component
        :is="component"
        :as="as"
        :data="data"
        :method="method"
        :href="href"
        :disabled="disabled"
        :class="{
            'bg-orange hover:bg-transparent hover:text-orange border border-orange text-white':
                !warning && !white && !transparent && !danger && !pink && !pinkTransparent && !blue,
            'bg-red hover:bg-red-700 text-white': danger,
            'bg-white hover:bg-white  text-blue border-blue': white,
            'bg-pink  hover:bg-pink/80 border text-white border-pink': pink,
            'bg-blue  hover:bg-blue/80 border text-white border-blue': blue,

            'bg-pink hover:bg-transparent border hover:border-pink hover:text-pink text-white border-pink':
                pinkTransparent,
            'bg-transparent hover:bg-white/10  text-green-500 border-green-500 border': transparent,
        }"
        class="relative inline-flex items-center self-center px-8 py-2 text-base font-semibold leading-none tracking-widest uppercase transition-colors duration-300 border border-transparent rounded disabled:bg-gray-200 min-h-10 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-gray-600 disabled:border-0 disabled:cursor-not-allowed"
    >
        <DefaultInner v-if="!slots.default" :icon="icon" :iconRight="iconRight" :text="text" />
        <slot></slot>
    </component>
</template>
